import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { events } from '../data/events';
import L from 'leaflet';
import { getEventMarkerIcon } from '../utils/utils';

export default class ConferencesMap extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: 48,
      lng: -42,
      zoom: 1.5,
      zoomSnap: 0.4,
    };
  }

  render() {
    const position = [this.state.lat, this.state.lng];
    if (typeof window !== 'undefined') {
      return (
        <Map
          center={position}
          zoom={this.state.zoom}
          zoomSnap={this.state.zoomSnap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
          />
          {events
            .filter(event => event.position != undefined)
            .map(event => {
              let zIndexOffset =
                event.family === 'bbl'
                  ? -1000
                  : event.family === 'meetup' ||
                    event.family === 'craftsrecords'
                    ? -500
                    : 0;
              let icon = getEventMarkerIcon(event);

              return (
                <Marker
                  key={event.id}
                  position={event.position}
                  zIndexOffset={zIndexOffset}
                  icon={L.icon({
                    iconUrl: icon,
                    iconSize: new L.Point(30, 30),
                  })}
                >
                  <Popup>{event.title}</Popup>
                </Marker>
              );
            })}
        </Map>
      );
    }
    return null;
  }
}
